import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Chart, TooltipItem} from "chart.js/auto";
import {ChartType, TypeChartModal} from "../../../core/enums/enums";
import {PinPad} from "@clavisco/core";
import Enums = PinPad.Enums;

@Component({
  selector: 'app-chart-modal-documents',
  templateUrl: './chart-modal-documents.component.html',
  styleUrls: ['./chart-modal-documents.component.scss']
})
export class ChartModalDocumentsComponent implements OnInit {


  chartDocument: Chart | undefined; // chart de Ventas de los últimos 30 días por día. (USD)

  //GRAFICOS PASTEL
  //chartData: any[] = []; // Informacion del correo
  chartColorData: any[] = []; //almacenara los colores para usar en el grafico
  chartColorBorderData: any[] = []; //almacenara los colores de los bordes para usar en el grafico

  @ViewChild('canvas', {static: true}) canvasRef!: ElementRef<HTMLCanvasElement>;
  constructor(
    private dialogReft:  MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data : any,
  ) { }

  ngOnInit(): void {
    this.OnLoad();
  }

  OnLoad():void{


    console.log(this.data);

    if(this.data){
      this.DimanicsColorForchartPie();
      this.PrintChart();
    }
  }



  DimanicsColorForchartPie():void {



      this.chartColorData.length = 0;
      this.chartColorBorderData.length = 0;

      if(this.data.Type== TypeChartModal.IssuedDocuments){

      // Define un mapeo de estados a colores
      const colorMapping: any = {
        'Aceptado': 'rgba(206, 224, 176, 1)',
        'Procesando': 'rgba(171, 220, 241, 1)',
        'Hacienda': 'rgba(246, 201, 209, 1)',
        'Rechazado': 'rgba(255, 220, 187, 1)',
        'Error': 'rgba(255, 240, 194, 1)',
        'AnuladosInternamente': 'rgba(226, 208, 204, 1)',
        // Agrega más estados y colores según sea necesario
      };

      const colorBorderMapping: any = {
        'Aceptado': 'rgba(100, 120, 80, 1)',
        'Procesando': 'rgba(50, 100, 150, 1)',
        'Hacienda': 'rgba(150, 80, 90, 1)',
        'Rechazado': 'rgba(150, 100, 70, 1)',
        'Error': 'rgba(120, 80, 40, 1)',
        'AnuladosInternamente': 'rgba(120, 100, 90, 1)',
        // Agrega más estados y colores según sea necesario
      };

        for (const status in this.data.Quantities) {

          if(this.data.Quantities[status] > 0){
            const color = colorMapping[status];
            const colorBorder = colorBorderMapping[status];

            if (color) {
              // Si hay un color mapeado para el estado, haz push a la lista de colores
              this.chartColorData.push(color);
              this.chartColorBorderData.push(colorBorder);
            }
          }


        }

      }if(this.data.Type== TypeChartModal.AcceptedDocuments) {
        const colorMapping: any = {
          'Aceptado': 'rgba(242, 255, 236, 1)',
          'Procesando': 'rgba(237, 234, 255, 1)',
          'Hacienda': 'rgba(253, 239, 255, 1)',
          'Rechazado': 'rgba(255, 235, 235, 1)',
          'Error': 'rgba(255, 255, 235, 1)',
          'Correo': 'rgba(228, 212, 203, 1)',
          'CorreoAutomatico': 'rgba(182, 159, 146, 1)',

          // Agrega más estados y colores según sea necesario
        };

        const colorBorderMapping: any = {
          'Aceptado': 'rgba(100, 120, 80, 1)',
          'Procesando': 'rgba(50, 100, 150, 1)',
          'Hacienda': 'rgba(150, 80, 90, 1)',
          'Rechazado': 'rgba(150, 100, 70, 1)',
          'Error': 'rgba(120, 80, 40, 1)',
          'Correo': 'rgba(140, 110, 100, 1)',
          'CorreoAutomatico': 'rgba(120, 100, 90, 1)',

          // Agrega más estados y colores según sea necesario
        };

      for (const status in this.data.Quantities) {

        if(this.data.Quantities[status] > 0){
          const color = colorMapping[status];
          const colorBorder = colorBorderMapping[status];

          if (color) {
            // Si hay un color mapeado para el estado, haz push a la lista de colores
            this.chartColorData.push(color);
            this.chartColorBorderData.push(colorBorder);
          }
        }


      }
      }


      // Supongamos que this.correoData es una lista de objetos con un campo "estado"


  }
  //Grafico pastel
  PrintChart():void{


    var numCount = document.getElementById('numCount');

    // Cambiar el cursor del ratón del elemento del gráfico a una mano (pointer)
    var chartPie = document.getElementById('canvas');
    chartPie!.style.cursor = 'pointer';

    // Obtener los datos para el gráfico de pastel

    var labels:any = []
    var quantity:any =[]

    for (const item in this.data.Quantities) {
      if(this.data.Quantities[item] > 0 ){
        labels.push(item)
        quantity.push(this.data.Quantities[item])
      }
    }

    console.log(quantity);
    console.log(labels);
    console.log(this.chartColorData);
    console.log(this.chartColorBorderData);

    var percent:any = [];
    for (const x in this.data.Percentages) {
      if(this.data.Percentages[x] > 0 ){
        percent.push(this.data.Quantities[x])
      }
    }

    // var percent = [this.data.Percentages.Aceptado,
    //   this.data.Percentages.Procesando,
    //   this.data.Percentages.Hacienda,
    //   this.data.Percentages.Rechazado,
    //   this.data.Percentages.Error,
    //   this.data.Percentages.Cancelled
    // ]

    // Crear el gráfico de pastel
    this.chartDocument = new Chart('canvas', {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: percent,
            backgroundColor: this.chartColorData,
            borderColor: this.chartColorBorderData,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {

              label: function(tooltipItem: TooltipItem<'pie'>): string {
                const dataset = tooltipItem.dataset;
                const index = tooltipItem.dataIndex;
                const label = tooltipItem.label;
                const currentValue = dataset?.data[index];

                if (dataset && currentValue !== undefined) {
                  const percentage = currentValue + '%';
                  return `${label}: ${percentage} Total: ${quantity[index]}`;
                }

                return ''; // O cualquier otro valor de retorno que desees en otros casos
              }
            }
          }
        },

      },
    });

    // Mostrar la cantidad total en números
    const quantitiesValues = Object.values(this.data.Quantities);

    if (Array.isArray(quantitiesValues)) {
      const totalCorreos: number = quantitiesValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      numCount!.textContent = 'Cantidad total de documentos: ' + totalCorreos;
      numCount!.style.fontSize = '20px'
      console.log(totalCorreos); // Imprimirá la suma de los valores de Quantities
    } else {
      console.error('No se puede calcular la suma: Quantities no es un array');
    }


  }

}
