/*import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';


import {NavigationEnd, Router} from "@angular/router";
import {AppSettings, Settings} from "./app.settings";

import {environment} from "../environments/environment";
import {Repository} from "@clavisco/core";
import {LinkerService} from "@clavisco/linker";*/

import {Repository, Structures} from '@clavisco/core';
import { environment } from 'src/environments/environment';

import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { LinkerService } from '@clavisco/linker';

import {AppSettings, Settings} from "./app.settings";
import {IToastGlobalConfiguration} from "@clavisco/alerts";
import {Login} from "@clavisco/login";
import {AuthenticationService} from "./core/services/authentication.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit{
  title = 'cl_cl_mlt_fec_app_ui';

  loading: boolean = false;
  public settings: Settings;
  currentUser: any;
  constructor(/*private authService: AuthService,*/
              private router: Router,
              @Inject('LinkerService') private linkerService: LinkerService,
              public appSettings : AppSettings,
              private authService: AuthenticationService,
             ){
    this.settings = this.appSettings.settings;
    this.authService.currentCompany.subscribe(x => this.currentUser = x);
  /*  translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en');
    translate.use('en');*/
  }

  ngOnInit():void {
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: environment.ApiAppUrl,
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'overlaysConfiguration',
      value: JSON.stringify({
        loginMessage: 'Procesando, espere por favor',
      } as Login.Structures.IOverlaysGlobalConfiguration)
    });
    Repository.Behavior.SetTokenConfiguration({
      token: `Alerts`, // Identificador que va a guardar nuestras configuraciones, este se indica al inicio del documento
      settings: {
        toastDuration: 10000, // Tiempo que va a ser mostrada nuestra alerta en pantalla
        verticalPosition: 'top', // Posicón en vertical de la alerta, posible valores: ['top', 'bottom']
        horizontalPosition: 'right', // Posición horizontal de la alerta, posible valores :['start','center','end','left','right']
        darkMode: true, // Tema de la alerta, claro o oscuro
        defaultCloseText: `Close`, // Texto que va a tener el botón de cerrar de la alerta

      },
      override: true, /*Cuando está en true reemplaza todas las configuraciones viejas de este componente con las nuevas y cuando está en false solo actualiza las configuraciones que le indiquemos*/
    });


    //SE COMENTA YA QUE REQUIERE CAMBIOS EN EL ENDPOINT Y POR EL MOMENTO NO SE ESTA TOCANDO

    // Repository.Behavior.SetTokenConfiguration({
    //   token: Structures.Enums.TOKENS.LOGN,
    //   setting: 'sendRecoverPasswordEmail',
    //   value: {
    //     urlVariableToReplaceWithEmail: '#EMAIL', // Variable que se utilizara para agregar el correo del usuario
    //     endpointToRequest: `${environment.ApiAppUrl}api/Account/#EMAIL/SendRecoverPswdEmail` // Endpoint para solicitar correo de recuperacion de contraseña
    //   } as Login.Structures.ISendRecoverPasswordEmailConfig
    // })
    //
    // Repository.Behavior.SetTokenConfiguration({
    //   token: Structures.Enums.TOKENS.LOGN,
    //   setting: 'recoverPassword',
    //   value: {
    //     endpointToRequest: `${environment.ApiAppUrl}api/Account/RecoverPswd`
    //   }  as Login.Structures.IChangePasswordConfig
    // })
    //
    // Repository.Behavior.SetTokenConfiguration({
    //   token: Structures.Enums.TOKENS.LOGN,
    //   setting: 'changePassword',
    //   value: {
    //     endpointToRequest: '<endpoint para cambio de contraseña>'
    //   } as Login.Structures.IChangePasswordConfig
    // })
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
  }

 /* ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }
      }
    })
  }*/
}
