import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {StorageService} from "./storage.service";
import {Router} from "@angular/router";
import {GeneralConfigsResponse} from "../../models/responses";

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigsService {
  private NameAction = new BehaviorSubject<string>('');
  Name = this.NameAction.asObservable();
  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) {}

  // edita las configuraciones generales existentes en la DB
  EditGeneralConfigs(genConfigId: number, selectedFile: File) {
    const token = this.storage.GetCurrentSession();

    return Observable.create(function (observer:any) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access_token}`,
        'API': 'ApiAppUrl'
      });
      let XHR = new XMLHttpRequest();
      const fd: FormData = new FormData();

      fd.append('filePrintFormat', selectedFile);

      XHR.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          let response = JSON.parse(XHR.responseText);
          if (response.result) {
            observer.next(response);
          } else {
            observer.error(response);
          }
        }
      };
      // Stop blocking
      XHR.open(
        'POST',
        `api/GeneralConfigs/EditGeneralConfigs?genConfigId=${genConfigId}`,
        true
      );
      XHR.setRequestHeader('Authorization', `Bearer ${token.access_token}`);
      // Send our FormData object; HTTP headers are set automatically
      return XHR.send(fd);
    });
  }

  // obtiene las configuraciones generales existentes en la DB
  GetGeneralConfigs() {
    const token = this.storage.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<GeneralConfigsResponse>(
      `api/GeneralConfigs/GetGeneralConfigs`,
      { headers }
    );
  }

  //Establece el nombre de la ventana activa en la barra de menú.
  SetNameAction(Action:string):void{
    this.NameAction.next(Action);
  }
}
