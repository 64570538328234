import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {GetError, Repository} from "@clavisco/core";
import {StorageService} from "../core/services/storage.service";
import {FeCredentialsResponse} from "../models/responses";
import {CLModalType, ModalService} from "@clavisco/alerts";
import {AuthenticationService} from "../core/services/authentication.service";
import {environment} from "../../environments/environment";
import {DefaultUrlSerializer, Router, UrlTree} from "@angular/router";
import {LoginService} from "@clavisco/login";
import {Token, TokenFE} from "../models/FeCredentialsModel";

@Injectable()
export class LoginInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService,
              private aut: AuthenticationService,
              private modalService: ModalService,
              private loginService: LoginService,
              private router: Router,) {}

  //se encarga de realizar el segundo login cuando se recibe una respuesta positiba del primer api
  /**
   * Intercepta las solicitudes HTTP y maneja las respuestas (su uso principal es en el manejo de la sesion, al realizar el inicio de sesion en el api de FE).
   * @param request La solicitud HTTP.
   * @param next El próximo controlador HTTP.
   * @returns Un observable de eventos HTTP.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && request.url === environment.ApiAppUrl + "token") {
          const responseData = event.body;
          console.log(responseData);
          this.storage.SetCurrentSession(responseData);
          this.GetFECredentials(responseData.companyId, responseData.access_token);
          this.storage.SetSelectedCompany(responseData.CompanyName, Number(responseData.companyId), responseData.CodigoActividad,
            Number(responseData.GroupId),responseData.UseFactProv.toLowerCase() === "true" , responseData.SendReceptAndApInv.toLowerCase() === "true" );
        }
        return event;
      })
    );
  }
  /**
   * Obtiene las credenciales de FE y realiza el segundo inicio de sesión.
   * @param companyId El ID de la compañía.
   * @param token El token de acceso.
   */
  GetFECredentials(companyId: string, token: string):void{
    this.aut.getFeCredentials(parseInt(companyId),token).subscribe({
        next:(data:FeCredentialsResponse):void=>{
          if(data.result){
            this.FELogin(data.FeCredentials.UserId,data.FeCredentials.Password)
          }
        },
        error: (err):void => {
          this.modalService.Continue({
            title: 'Se produjo un error al obtener las credenciales de FE',
            subtitle: GetError(err),
            type: CLModalType.ERROR
          });
        }
      }
    );
  }
  /**
   * Realiza el segundo inicio de sesión con las credenciales de FE.
   * @param userName El nombre de usuario de FE.
   * @param password La contraseña de usuario de FE.
   */
  private FELogin(userName: string, password: string):void{

    this.loginService.Login(userName, password, environment.ApiFEUrl,'FRAMEWORK').subscribe({
        next:(data)=>{
          if(data){
            this.storage.SetCurrentFESession(data as TokenFE);
            const urlSerializer = new DefaultUrlSerializer();
            // Ejemplo de una ruta secundaria
            const route = environment.url+'login/home';
            const urlTree: UrlTree = this.router.createUrlTree(['/home'], { });
            this.router.navigateByUrl(urlTree);
          }
        },
        error: (err):void => {
          this.modalService.Continue({
            title: 'Se produjo un error al intentar ingresar a la aplicación',
            subtitle: GetError(err),
            type: CLModalType.ERROR
          });
        }

      }
    );
  }
}
