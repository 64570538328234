import { Injectable } from '@angular/core';
import {CompaniesService} from "./companies.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {BehaviorSubject, filter, fromEvent, map, Observable, tap} from "rxjs";
import {BaseResponse, CertExpireDateAlarmResponse} from "../../models/responses";
import {NumberingIntegrationTypes} from "../enums/enums";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  //@BlockUI() blockUI: NgBlockUI;
  public url = 'assets/data/';



  constructor(
    private companiesService: CompaniesService,
    //private alertService: AlertService,
    public http: HttpClient,
    private storageService: StorageService,
    private alertService: AlertsService,
  ) {}

  GetCertExpireDateAlarm(companyId: number):void {
    this.companiesService.GetCertExpireDateAlarm(companyId).pipe(
      map((data: BaseResponse) => {
        // Realiza la conversión del tipo BaseResponse a CertExpireDateAlarmResponse aquí
        return data as CertExpireDateAlarmResponse;
      }),

      tap((data:CertExpireDateAlarmResponse)=>{
      //this.blockUI.stop();
      if(data.result){
        if(data.showAlarm){
          this.alertService.Toast({ message: data.smsAlert,
            type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
        }
      }else{
        this.alertService.Toast({ message: `${data.errorInfo.Message}`,
          type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
      }
    })).subscribe();
  }

  ConvertEnumToArray<E>(e: any):any {
    const arrayObjects = [];
    // Retrieve key and values using Object.entries() method.
    for (const [propertyKey, propertyValue] of Object.entries(e)) {
      // Ignore keys that are not numbers
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }

      // Add keys and values to array
      arrayObjects.push({ Id: propertyValue, Name: propertyKey });
    }
    return arrayObjects;
  }


  public CheckPermission(permissionName: string): boolean {

    let permissionList: string[] = JSON.parse(this.storageService.GetItem<string>('permissions'));

    if (permissionList && permissionList.length) {
      return permissionList.some((permission) => permission === permissionName);
    } else {
      return false;
    }
  }

  private escKeySubject:Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
    filter((event) => event.key === 'Escape')
  );

  escKeyPress(): Observable<KeyboardEvent> {
    return this.escKeySubject;
  }

  //Date Formatting Function (yyyy-MM-dd)
  FormatDate(_date: string | Date): string {
    if (_date) {
      return formatDate(_date, 'yyyy-MM-dd', 'en');
    } else {
      return formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

  }

}
