import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";
import {BaseResponse, IResponse, PermissionsSelectedResponse, PermsByRolResponse} from "../../models/responses";
import {PermissionsModel} from "../../models/perms";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PermsService {

  constructor(private http: HttpClient, private storage: StorageService) { }

// obtiene los permisos de la aplicacion
  GetPerms():Observable<PermissionsSelectedResponse> {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<PermissionsSelectedResponse>(
      `api/Permission/GetPermissions`,
      { headers }
    );
  }

  // obtiene los permisos de la aplicacion por usuario
  GetPermsByUser(companyId: number):Observable<PermissionsSelectedResponse> {
    const TOKEN = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<PermissionsSelectedResponse>(
      `api/Permission/GetPermsByUser?companyId=${companyId}`,
      { headers }
    );
  }

  // obtiene los permisos por un rol especifico
  GetPermsByRol(idRol: number):Observable<PermsByRolResponse> {
    const token =  this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<PermsByRolResponse>(
      `api/Permission/GetPermissionsByRol?idRol=${idRol}`,
      { headers }
    );
  }

  // funcion para guardar la asignacion de permisos en el rol selecionado
  SavePermsByRol(permsRolModel:any):Observable<BaseResponse> {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.post<BaseResponse>(
      `api/Permission/AssignPermByRol/`,
      permsRolModel,
      { headers }
    );
  }
}
