import {Inject, Injectable} from '@angular/core';
import {StorageService} from "./storage.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {
  BaseResponse,
  CertExpireDateResponse,
  CompaniesResponse,
  CompanyInfoResponse, DimensionsResponse, FreightResponse,
  IResponse,
  SAPConnectionsResponse,
  TaxesResponse
} from "../../models/responses";
import {UntypedFormGroup} from "@angular/forms";
import {BehaviorSubject, catchError, finalize, Observable, Subscriber, Subscription, tap} from "rxjs";
import {ICompany} from "../interfaces/i-company";
import {IUdf} from "../interfaces/i-udf";
import {DatePipe} from "@angular/common";
import {OverlayService} from "@clavisco/overlay";
import {CLModalType, ModalService} from "@clavisco/alerts";
import {Structures} from "@clavisco/core";


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private dataSubject = new BehaviorSubject<any>(null);
  data = this.dataSubject.asObservable();
  private executionTypeSubject = new BehaviorSubject<any>(2);
  executionType = this.executionTypeSubject.asObservable();
  private companySubject = new BehaviorSubject<any>(null);
  company = this.companySubject.asObservable();


  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private datePipe: DatePipe,
    @Inject('OverlayService') private overlayService: OverlayService,
    private modalService: ModalService
  ) { }

  SendData(company: any, executionType: number):void{
    if(executionType == 1) {
      this.dataSubject.next(company);
      this.executionTypeSubject.next(1);
    }else{
      this.ClearData();
    }
  }
  ClearData(): void {
    this.dataSubject.next(null);
    this.executionTypeSubject.next(null);
  }


  GetCompanies(comercialName: string, legalName: string, identification: string): Observable<CompaniesResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const search = {
      ComercialName: comercialName,
      LegalName: legalName,
      Identification: identification,
    };
    return this.http.post<CompaniesResponse>(
      `api/Companies/GetCompanies`,
      search,
      { headers }
    );
  }

  GetCompaniesPaginator(
    legalName: string,
    comercialName: string,
    identification: string,
    _pageNumber: number,
    _itemsPeerPage: number
  ): Observable<CompaniesResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const search = {
      LegalName: legalName,
      ComercialName: comercialName,
      Identification: identification,
      StartPos: _pageNumber,
      StepPos: _itemsPeerPage,
      RequirePagination: true,
    };
    return this.http.post<CompaniesResponse>(
      `api/Companies/GetCompanies`,
      search,
      { headers }
    );
  }

  GetCompanyInfoById(idCompany: number): Observable<CompanyInfoResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const companyId = {
      Id: idCompany,
    };
    return this.http.post<CompanyInfoResponse>(
      `api/Companies/GetCompanyInfoById`,
      companyId,
      { headers }
    );
  }

  //obtiene los codigos de impuesto desde SAP
  GetTaxCode(compId: number): Observable<TaxesResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const URL = `api/Tax/GetTaxes?CompanyId=${compId}`;
    return this.http.get<TaxesResponse>(URL, { headers });
  }


  CreateCompany(companyForm: UntypedFormGroup,
                atvForm: UntypedFormGroup,
                companyId: number,
                userIsSys: boolean,
                groupId: number,
                selectedFile: File,
                selectedFEPrintFormatFile: File,
                selectedLogoFile: File,
                additionalInformationForm: UntypedFormGroup,
                sapForm: UntypedFormGroup): Observable<BaseResponse> {

    const token = this.storageService.GetCurrentSession();
    const TOKENFE = this.storageService.GetCurrentFESession();
    const headers = new HttpHeaders({
      //'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'Request-With-Files': 'true',
      'API': 'ApiAppUrl'
    });


    const fd: FormData = new FormData();

    const company: ICompany = {
      Id: 0,
      ComercialName: companyForm.value.ComercialName,
      LegalName: companyForm.value.LegalName,
      Identification: companyForm.value.Identification,
      Type: companyForm.value.Type,
      CertPath: atvForm.value.CertPath,
      CertPin: atvForm.value.CertPin,
      TokenUsr: atvForm.value.TokenUsr,
      grant_type: '',
      client_id: '',
      EnvironmentId: 0,
      Attempts: 0,
      Busy: false,
      EmsrNombre: companyForm.value.ComercialName,
      EmsrIdeTipo: companyForm.value.Type,
      EmsrNombreComercial: companyForm.value.ComercialName,
      EmsrIdeNumero: companyForm.value.Identification,
      AdditionalInformation: additionalInformationForm.value.AdditionalInformation,
      CodigoActividad: companyForm.value.CodigoActividad,
      EmailCC: additionalInformationForm.value.EmailCC.join(';'),
      SAPConnectionId: sapForm.value.SAPConnectionID,
      DBSap: sapForm.value.DBSap,
      DBMaestraSap: sapForm.value.DBMaestraSap,
      NumSerieProv: sapForm.value.NumSerieProv,
      NumSerieFactProv: sapForm.value.NumSerieFactProv,
      CertExpireDate: atvForm.value.CertExpireDate,
      NameToEmail: companyForm.value.NameToEmail,
      ShortName: companyForm.value.ShortName,
      ToleranciaMontoXML: sapForm.value.ToleranciaMontoXML,
      DefaultTaxForXML: sapForm.value.DefaultTaxForXML,
      UseFactProv: companyForm.value.UseFactProv,
      SendReceptAndApInv: companyForm.value.SendReceptAndApInv,
      AttachmentPath: sapForm.value.AttachmentPath,
      FreightCharges:companyForm.value.FreightCharges
  } as ICompany;

    let grpId = groupId;
    // tslint:disable-next-line: radix
    if (userIsSys && groupId !== parseInt(companyForm.value.GroupId)) {
      // tslint:disable-next-line: radix
      grpId = parseInt(companyForm.value.GroupId);
    }

    fd.append('company', JSON.stringify(company));
    fd.append('file', selectedFile);
    fd.append('fileFEPrintFormat', selectedFEPrintFormatFile);
    fd.append('fileLogo', selectedLogoFile);

    return this.http.post<BaseResponse>(
      `api/Companies/CreateCompanyByUser?companyId=${companyId}&grpId=${grpId}&feToken=${TOKENFE.access_token}`,
      fd,
      { headers }
    );
  }

  // action 1 = edit company info, 2 = edit atv info, 3 = att info
  EditCompany(action: number,
              companyForm: UntypedFormGroup,
              atvForm: UntypedFormGroup,
              companyId: number,
              userIsSys: boolean,
              groupId: number,
              selectedFile: File,
              selectedFEPrintFormatFile: File,
              selectedLogoFile: File,
              changeCompanyInfo: boolean,
              changeATVInfo: boolean,
              changeAttInfo: boolean,
              additionalInformationForm: UntypedFormGroup,//pendiente revisar si se necesita
              sapForm: UntypedFormGroup
              ): Observable<BaseResponse>{
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      //'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'Request-With-Files': 'true',
      'API': 'ApiAppUrl'
    });


    const fd: FormData = new FormData();

    const company: ICompany = {
      Id: companyId,
      ComercialName: companyForm.value.ComercialName,
      LegalName: companyForm.value.LegalName,
      Identification: companyForm.value.Identification,
      Type: companyForm.value.Type,
      CertPath: atvForm.value.CertPath,
      CertPin: atvForm.value.CertPin,
      TokenUsr: atvForm.value.TokenUsr,
      grant_type: '',
      client_id: '',
      EnvironmentId: 0,
      Attempts: 0,
      Busy: false,
      EmsrNombre: companyForm.value.LegalName,
      EmsrIdeTipo: companyForm.value.Type,
      EmsrNombreComercial: companyForm.value.ComercialName,
      EmsrIdeNumero: companyForm.value.Identification,
      AdditionalInformation: additionalInformationForm.value.AdditionalInformation,
      CodigoActividad: companyForm.value.CodigoActividad,
      EmailCC: additionalInformationForm.value.EmailCC.join(';'),
      SAPConnectionId: sapForm.value.SAPConnectionId,
      DBSap: sapForm.value.DBSap,
      DBMaestraSap: sapForm.value.DBMaestraSap,
      NumSerieProv: sapForm.value.NumSerieProv,
      NumSerieFactProv: sapForm.value.NumSerieFactProv,
      CertExpireDate: atvForm.value.CertExpireDate,
      NameToEmail: companyForm.value.NameToEmail,
      ShortName: companyForm.value.ShortName,
      ToleranciaMontoXML: sapForm.value.ToleranciaMontoXML,
      DefaultTaxForXML: sapForm.value.DefaultTaxForXML,
      UseFactProv: companyForm.value.UseFactProv,
      SendReceptAndApInv: companyForm.value.SendReceptAndApInv,
      AttachmentPath: sapForm.value.AttachmentPath,
      FreightCharges:companyForm.value.FreightCharges
    } as ICompany;

    let grpId = groupId;
    if (userIsSys && groupId !== parseInt(companyForm.value.GroupId)) {
      grpId = parseInt(companyForm.value.GroupId);
    }

    fd.append('company', JSON.stringify(company));
    fd.append('file', selectedFile);
    fd.append('fileFEPrintFormat', selectedFEPrintFormatFile);
    fd.append('fileLogo', selectedLogoFile);

    return this.http.post<BaseResponse>(
      `api/Companies/EditCompanyByUser?grpId=${grpId}&action=${action}`,
      fd,
      { headers }
    );
  }

  SetFavoriteCompany(compId: number): Observable<BaseResponse>{

    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    const companyId = {
      id: compId,
    };

    return this.http.post<BaseResponse>(
      `api/Companies/SetFavoriteCompany`,
      companyId,
      { headers }
    );
  }


  GetCertExpireDateAlarm(companyId: number): Observable<BaseResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<BaseResponse>(
      `api/Companies/GetCertExpireDateAlarm?companyId=${companyId}`,
      { headers }
    );
  }

  GetCertExpireDate(
    CertPin: string,
    selectedFile: File,
    CertExpireDate: any
  ):Subscription {

    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token.access_token}`,
      'Request-With-Files': 'true',
      'API': 'ApiAppUrl'
    });
    const fd: FormData = new FormData();

    fd.append('file', selectedFile);

    return this.http.post<BaseResponse>(
      `api/Companies/CheckCertExpireDate?CertPin=${CertPin}`,
      fd,
      { headers }
    ).pipe(tap((responses: any) => {
      this.overlayService.OnGet();
      if(responses.result){
        CertExpireDate.setValue(this.datePipe.transform(new Date(responses.CertExpireDate), 'dd/MM/yyyy HH:mm:ss'));
      }else{
        CertExpireDate.setValue('');
        this.modalService.Open({
          type: CLModalType.WARNING,
          subtitle: '',
          disableClose: false,
          title: responses.errorInfo.Message,
          options: {
            ConfirmButton: {
              Action: Structures.Enums.CL_ACTIONS.CONTINUE,
              Color: 'primary',
              Title: 'Ok',
              Data: '',
              Icon: ''
            },
          }
        }).pipe(tap()).subscribe();
      }
    }), catchError((error)=>{
      console.log(error);

      this.modalService.Open({
        type: CLModalType.WARNING,
        subtitle: '',
        disableClose: false,
        title: error,
        options: {
          ConfirmButton: {
            Action: Structures.Enums.CL_ACTIONS.CONTINUE,
            Color: 'primary',
            Title: 'Ok',
            Data: '',
            Icon: ''
          },
        }
      }).pipe(tap()).subscribe();

      throw error;
    }) ,finalize(() => this.overlayService.Drop())).subscribe();
  }

  GetSAPConnections(): Observable<SAPConnectionsResponse> {
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<SAPConnectionsResponse>(
      `api/Companies/GetSAPConnections`,
      { headers }
    );
  }

  GetCompaniesByUserGroup(companyId: number): Observable<CompaniesResponse> {
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<CompaniesResponse>(
      `api/Companies/GetCompaniesByUserGroup?companyId=${companyId}`,
      { headers }
    );
  }

  //UDFs
  GetUdfs(companyId: number, searchUdfsForHeader: boolean): Observable<IResponse<IUdf[]>> {
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<IResponse<IUdf[]>>(
      `api/Udf/GetUdfs?companyId=${companyId}&searchUdfsForHeader=${searchUdfsForHeader}`,
      { headers }
    );
  }

  GetConfiguredUdfsByCompany(companyId: number, category: boolean): Observable<IResponse<IUdf[]>> {
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<IResponse<IUdf[]>>(
      `api/Udf/GetConfiguredUdfs?companyId=${companyId}&Category=${category}`,
      { headers }
    );
  }

  SaveUdfs(dataToSave:any): Observable<BaseResponse>{

    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.post<BaseResponse>(
      `api/Udf/SaveUdfs`,
      dataToSave,
      { headers }
    );

  }

  // restablece el formato de impresion de la compania al por defecto
  ResetPrintFormat(idCompany: number): Observable<BaseResponse> {
    const token = this.storageService.GetCurrentSession();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });

    return this.http.get<BaseResponse>(
      `api/Companies/ResetCompanyPrintFormat?idCompany=${idCompany}`,
      { headers }
    );
  }

  // obtiene las dimensiones activas y centros de costo
  GetDimensionsAndCntrCost(idCompany: number): Observable<DimensionsResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<DimensionsResponse>(
      `api/Companies/GetDimensionsAndCntrCost?companyId=${idCompany}`,
      { headers }
    );
  }

  // obtiene los gastos adicionales
  GetAdditionalFreights(idCompany: number): Observable<FreightResponse> {
    const token = this.storageService.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<FreightResponse>(
      `api/Companies/GetAdditionalFreights?companyId=${idCompany}`,
      { headers }
    );
  }
}
