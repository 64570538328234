import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  AllCompaniesResponse,
  BaseResponse,
  MailParserConfigResponse
} from "../../models/responses";
import {MailParserConfigModel} from "../interfaces/i-mailParserConfig";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MailParserService {

  constructor(
    private storage: StorageService,
    private http: HttpClient,
  ) { }

  GetMailParserPaginator(
    mailServer: string,
    email: string,
    companyName: string,
    status: number,// id de la compañía
    useToken: number,// id del grupo
    _pageNumber: number,
    _stepPos: number,
  ):Observable<MailParserConfigResponse> {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const search = {
      MailServer: mailServer,
      Mail: email,
      Status:status,
      EmsrNombre:companyName,
      UseToken:useToken,
      StartPos: _pageNumber,
      StepPos: _stepPos,

    };
    return this.http.post<MailParserConfigResponse>(
      `api/MailParser/GetMailParserByFilters`,
      search,
      { headers }
    );
  }

  CreateMailParser(mailparser:MailParserConfigModel):Observable<BaseResponse>  {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const parser = mailparser
    return this.http.post<BaseResponse>(
      `api/MailParser/CreateMailParser`,
      parser,
      { headers }
    );
  }

  EditMailParser(mailparser:MailParserConfigModel):Observable<BaseResponse>  {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    const parser = mailparser
    return this.http.post<BaseResponse>(
      `api/MailParser/EditMailParser`,
      parser,
      { headers }
    );
  }


  // metodo que obtiene las sucursales de la compañía seleccionada
  public GetAllCompanies():Observable<AllCompaniesResponse>  {
    const token = this.storage.GetCurrentSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<AllCompaniesResponse>(
      `api/MailParser/GetCompanies`,
      { headers }
    );
  }


}
