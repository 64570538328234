import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {Globals} from "../../Globals";
import {StorageService} from "../services/storage.service";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {Structures} from "@clavisco/core";
import {MConfig, MDoc} from "../enums/enums";
import {PermissionsSelectedModel} from "../interfaces/i-perms";
import {Token} from "../../models/FeCredentialsModel";

@Injectable({
  providedIn: 'root'
})
export class VerifyPermissionsGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private globals: Globals,
    private storage: StorageService,
    private alertService: AlertsService,
    private modalService: ModalService,
  ) { }
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let havePerm: boolean = false;
    let newRoute: string = route.url.map(x => x.path).join('-');
    let perms: PermissionsSelectedModel[]= this.storage.GetPerms();
    let requiredPerm: string = this.GetRequiredPerm(newRoute);
    const currentUser:Token = this.storage.GetCurrentSession();
    var today = new Date();
    if(perms){
      havePerm = perms.some((perm:any) => perm.Name === requiredPerm);
    }
    if(today <= new Date(currentUser.ExpireTime)){
      if (havePerm || (newRoute === 'generalConfigs' && requiredPerm === currentUser.UserName)) {
        if(!perms.some((perm:any) => perm.Name === 'M_Documents')){
          if(Object.values(MDoc).includes(requiredPerm)){
            this.Mensaje();
            return false;
          }
        }

        if(!perms.some((perm:any) => perm.Name === 'M_Config')){
          if(Object.values(MConfig).includes(requiredPerm)){
            this.Mensaje();
            return false;
          }
        }

        if(requiredPerm=== 'M_Reports' && !perms.some((perm:any) => perm.Name === 'S_DocumentReport') && !perms.some((perm:any) => perm.Name === 'S_DocumentReceptionReport')){
            this.Mensaje();
            return false;
        }
        return true;

        //this.alertService.Toast({ message: 'Acceso denegado, no posee permisos para realizar esta acción', type: CLToastType.ERROR })
      }else{
        this.Mensaje();
        return false;
      }
    }
    this.modalService.Open({
      type: "info" as CLModalType,
      subtitle: 'Token de autorización no válido',
      disableClose: false,
      title: '',
      options: {
        ConfirmButton: {
          Action: Structures.Enums.CL_ACTIONS.CONTINUE,
          Color: 'primary',
          Title: 'Continuar',
          Data: '',
          Icon: ''
        },
        YesNoQuestion: false
      }
    })
    this.authenticationService.logout();
    return false;
  }

  Mensaje():void{
  this.modalService.Open({
    type: "info" as CLModalType,
    subtitle: 'Acceso denegado, no posee permisos para realizar esta acción',
    disableClose: false,
    title: '',
    options: {
      ConfirmButton: {
        Action: Structures.Enums.CL_ACTIONS.CONTINUE,
        Color: 'primary',
        Title: 'Continiar',
        Data: '',
        Icon: ''
      },
      YesNoQuestion: false
    }
  })
    .subscribe({next: (result:boolean):void => {
        if (result) {
          this.router.navigate(['/home']);
        }
      }
    });
}
  GetRequiredPerm(route: string):string {
    let requiredPerm: string = '';
    switch (route) {
      case 'mailParser': {
        requiredPerm = 'S_MailParserLogs';
        break;
      }
      case 'updateUserInfo': {
        requiredPerm = 'S_UpdateUserInfo';
        break;
      }
      case 'company': {
        requiredPerm = 'S_Company';
        break;
      }
      case 'completeUsersRegistration': {
        requiredPerm = 'S_CompUser';
        break;
      }
      case 'createDocument-01': {
        requiredPerm = 'S_CreateDocsFE';
        break;
      }
      case 'createDocument-02': {
        requiredPerm = 'S_CreateDocsND';
        break;
      }
      case 'createDocument-03': {
        requiredPerm = 'S_CreateDocsNC';
        break;
      }
      case 'createDocument-04': {
        requiredPerm = 'S_CreateDocsTE';
        break;
      }
      case 'createDocument-08': {
        requiredPerm = 'S_CreateDocsFEC';
        break;
      }
      case 'createDocument-09': {
        requiredPerm = 'S_CreateDocsFEE';
        break;
      }
      case 'docReport': {
        requiredPerm = 'M_Reports';
        break;
      }
      case 'issued_documents': {
        requiredPerm = 'S_RevDocs';
        break;
      }
      case 'accept_documents': {
        requiredPerm = 'S_AcceptDocs';
        break;
      }
      case 'reception_documents': {
        requiredPerm = 'S_ReceptDocs';
        break;
      }
      case 'generalConfigs': {
        requiredPerm = 'sys@clavisco.com';
        break;
      }
      case 'groups': {
        requiredPerm = 'S_Groups';
        break;
      }
      case 'userAssignment': {
        requiredPerm = 'S_AsigUser';
        break;
      }
      case 'numbering': {
        requiredPerm = 'S_Numbering';
        break;
      }
      case 'permsByRol': {
        requiredPerm = 'S_PermsByRol';
        break;
      }
      case 'registerUsers': {
        requiredPerm = 'S_RegUser';
        break;
      }
      case 'Rol': {
        requiredPerm = 'S_Rols';
        break;
      }
      case 'rolUserCompany': {
        requiredPerm = 'S_RolByUser';
        break;
      }
      case 'sucursal': {
        requiredPerm = 'S_Sucursal';
        break;
      }
      // PRUEBA
      case 'mailParserConfig': {
        requiredPerm = 'S_MailParserConfig';
        break;
      }
      case 'udfs': {
        requiredPerm = 'S_Udfs';
        break;
      }
    }
    return requiredPerm;
  }
}
