
export enum IdentificationType {
  CedulaFisica = 1,
  CedulaJuridica = 2,
  DIMEX = 3,
  NITE = 4
}

export enum ExecutionType{
  Edit = 1,
  Create = 2,
}

export enum UdfsValuesTypes {
  U_Schema = 'U_Schema',
  U_ConfirmVAT = 'U_ConfirmVAT'
}

// Enum para reconocer los tipos de numeraciones para las series de numeracion
export enum NumberingIntegrationTypes {
  Integrador = 1,
  AppFE = 2
}

export enum TypeDocSAP{
  NA = 0,//Nulo
  FirmDocument = 1, //Documento firme
  Draf =2
}

export enum DocTypes {
  Factura = 1,
  NC = 2,
  ND = 3
}

export enum DocStatusEnum {
  Aceptado = 1,
  Procesando = 2,
  EnHacienda = 3,
  Rechazado = 4,
  Error = 5,
  Reprocesar = 6,
  Cancelado  = 7,
}

export enum DocStatusStringEnum {
  Aceptado = 'Aceptado',
  Procesando = 'Procesando',
  EnHacienda = 'EnHacienda',
  Rechazado = 'Rechazado',
  Error = 'Error',
  Reprocesar = 'Reprocesar',
  Cancelado  ='Cancelado',
  Undefined = 'N/A'
}

export enum DocAcepStatusEnum {
  Aceptado = 1,
  Procesando = 2,
  EnHacienda = 3,
  Rechazado = 4,
  Error = 5,
  Reprocesar  = 6,
  ObtenidodelCorreo = 7 ,
  ObtenidodelCorreoAutomatico = 8

}

export enum DocAcepStatusStringEnum {
  Aceptado = 'Aceptado',
  Procesando = 'Procesando',
  EnHacienda = 'En Hacienda',
  Rechazado = 'Rechazado',
  Error = 'Error',
  Reprocesar = 'Reprocesar',
  ObtenidodelCorreo = 'Obtenido del Correo',
  ObtenidodelCorreoAutomatico = 'Obtenido del Correo Automatico',
  Undefined = 'N/A'
}


export enum DocStatus2 {
  Aceptado = 1,
  Procesando = 2,
  EnHacienda = 3,
  Rechazado = 4,
  Error = 5,
  Todos = 99
}



// Enum para los tipos de doc base
export enum TipoDocBaseEnum {
  Oferta = '01',
  Orden = '02',
  Entrada = '03',
  Ninguno = '04'
}

export enum DocTypeForBulkDownloadOfDocuments {
  Document = 1,
  Recept = 2
}

export enum ChartType {
  PieEmail = 1,
  PieDocument =2
}
export enum MDoc{
  S_CreateDocsFE,
  S_CreateDocsND,
  S_CreateDocsNC,
  S_CreateDocsTE,
  S_CreateDocsFEC,
  S_CreateDocsFEE,
  S_AcceptDocs,
  S_ReceptDocs,
  S_RevDocs,
  S_MailParserLogs
}

export enum MConfig{
  S_Company,
  S_CompUser,
  S_UpdateUserInfo,
  S_Groups,
  S_AsigUser,
  S_Numbering,
  S_PermsByRol,
  S_RegUser,
  S_Rols,
  S_RolByUser,
  S_Sucursal,
  S_MailParserConfig,
  S_Udfs
}

export enum TypeChartModal{
  IssuedDocuments =1,
  AcceptedDocuments = 2,
}



