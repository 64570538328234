import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor() {}

  /**
   * Intercepta todas las solicitudes HTTP salientes y modifica dinámicamente la URL base
   * según la API a la que se dirige la solicitud.
   * @param request La solicitud HTTP saliente que se está interceptando.
   * @param next El siguiente manipulador de la cadena de interceptores en la cola.
   * @returns Un observable que emite eventos HTTP resultantes de la solicitud modificada.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes(environment.ApiAppUrl) ||
      request.url.includes(environment.ApiFEUrl) ||
      request.url.includes(environment.ApiCabysURL)){
      return next.handle(request);
    }

    if(request.url.includes('assets')){
      return next.handle(request);
    }

    if(request.headers.has('API')) {
      const api = request.headers.get('API');
      switch (api){
        case 'ApiAppUrl':
          request = request.clone({url: `${environment.ApiAppUrl}${request.url}`});
          break;
        case 'ApiFEUrl':
          request = request.clone({url: `${environment.ApiFEUrl}${request.url}`});
          break;
        case 'ApiCabysURL':
          request = request.clone({url: `${environment.ApiCabysURL}${request.url}`});
          break;
      }
    }

    return next.handle(request);
  }
}
